import "./App.css";
import React from "react"
import Main from './main'

import { Routes, Route, HashRouter as Router, Link } from 'react-router-dom';
function App() {
  return <div className="App">      
    <Router>
      <Routes>
        <Route path="/" element={<Main />}/>

      </Routes>
    </Router>
  </div>
}

export default App;
