import './App.css';
import { useMemo } from 'react';
import * as anchor from '@project-serum/anchor';
import Home from './Home';
import { DEFAULT_TIMEOUT } from './connection';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { ThemeProvider, createTheme } from '@material-ui/core';
const Completionist = () => <span>MINT is LIVE!</span>;
interface CountdownRender {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
}
const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRender) => {
  if (completed) {
      // Render a complete state
      return <Completionist />;
  } else {
      // Render a countdown
      return (
          <span className='countdown'>
            
              <b>{days}</b>d : <b>{hours}</b>h : <b>{minutes}</b>m : <b>{seconds}</b>s
          </span>
      );
  }
};
const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const Main = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);
  let navigate = useNavigate();
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );
  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }
  return (
    <div className="main">
    <div id="mobileNavContainer" className="mobile-nav">
      <div className="mobile-nav-close-button">
        <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
      </div>
      <ul>
        <li>
          <img className="mobile-nav-logo" src="/img/logo.png" alt="" />
        </li>
        <li>
          <a onClick={ () => {toggleMenu(); navigate("/")}}>
            Home
          </a>
        </li>
       
   
        <li>
          <div className="social-icons">
          <a href="https://twitter.com/TimmyNFTortoise" target="_blank" rel="noreferrer">
            <img className="nav-social" src="/icons/twitter.svg" alt="" />
          </a>
          <a href="https://discord.gg/YHbdPX7Hvb" target="_blank" rel="noreferrer">
            <img className="nav-social" src="/icons/discord.svg" alt="" />
            </a>

          </div>
        </li>
      </ul>
    </div>
    <div className="mobile-menu-button" onClick={toggleMenu}>
      <img src="/icons/menu.svg" alt="" />
    </div>
    <nav>
      <div className="nav-container">
 
        <a className="hide-800 fst active" onClick={() => { navigate("/");}}>
          Home
        </a>
       
      
        
          <a href="https://twitter.com/TimmyNFTortoise" className="hide-800 twt" target="_blank" rel="noreferrer">
            <img className="nav-social" src="/icons/twitter.svg" alt="" />
          </a>
          <a href="https://discord.gg/YHbdPX7Hvb" className='hide-800 dsc' target="_blank" rel="noreferrer">
            <img className="nav-social" src="/icons/discord.svg" alt="" />
          </a>
          <a href="https://www.instagram.com/timmynftortoise" className='lst hide-800' target="_blank" rel="noreferrer">
            <img className="nav-social" src="/icons/instagram.svg" alt="" />
          </a>
      
      </div>
    </nav>
    <div className="content-wrapper">
      <h1 className='title'>Welcome to The Misfortunes of <br /> Timmy the Tortoise</h1>
      <br />
      <Countdown date={1656619200000} renderer={renderer} />
      <div className='mint-area'>
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <Home
              candyMachineId={candyMachineId}
              connection={connection}
              txTimeout={DEFAULT_TIMEOUT}
              rpcHost={rpcHost}
              network={network}
            />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
    </div>
    <p className='intro'>
    The Misfortunes of Timmy the Tortoise is the first collection of 4-panel 
    stories to ever exist as an NFT! In total, there are 10,000 relatable stories. 
    Each is a unique 1/1. Collect as many versions as you can to experience the highs 
    of Timmy’s rise to glory and the degeneracy that accompanies his fall from grace.
    </p>
    </div>
    </div>
  );
};

export default Main;
